export enum FirestoreCollections {
  PATIENTS = 'patients',
  PATIENT_LEADS = 'patient-leads',
  PROVIDERS = 'providers',
  PATIENT_WORKFLOWS = 'patient-workflows',
  TEMPLATE_WORKFLOWS = 'template-workflows',
  PATIENT_MESSAGES = 'patient-messages',
  PATIENT_LEAD_MESSAGES = 'patient-lead-messages',
  CONFIGS = 'configs',
  CLINIC_DETAILS = 'clinic-details',
  TWILIO = 'twilio',
  MAILMODO = 'mailModo',
  FORM_VARIABLES = 'form-variables',
  PATIENT_APPOINTMENTS = 'patient-appointments',
  FEATURE_FLAGS = 'feature-flags',
  TEMPLATE_MEDICATIONS = 'template-medications',
  ACUITY = 'acuity',
  PATIENT_APPOINTMENT_REQUESTS = 'patient-appointment-requests',
  VOICE_CALLS = 'voice-calls',
}

export enum AccessLevel {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export enum Gender {
  FEMALE = 'Female',
  MALE = 'Male',
  OTHER = 'Other',
  DEFAULT = '',
}

export enum MedicationFrequency {
  // Q24H = 'Q24H',
  Q12H = 'Q12H',
  Q8H = 'Q8H',
  // Q6H = 'Q6H',
  // Q4H = 'Q4H',
  QD = 'QD',
  QPACK = 'QPACK',
  DEFAULT = '',
}

export enum BackendErrorType {
  MISSING_PLACEHOLDER_VALUE = 'MISSING_PLACEHOLDER_VALUE',
  EMPTY_PLACEHOLDER = 'EMPTY_PLACEHOLDER',
  EMPTY_MESSAGE = 'EMPTY_MESSAGE',
  UNKNOWN_PLACEHOLDER = 'UNKNOWN_PLACEHOLDER',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  WORKFLOW_TASK_ELIGIBILITY_ERROR = 'WORKFLOW_TASK_ELIGIBILITY_ERROR',
  WORKFLOW_TASK_EXECUTION_TIME_ERROR = 'WORKFLOW_TASK_EXECUTION_TIME_ERROR',
  MEDICATION_ERROR = 'MEDICATION_ERROR',
  APPOINTMENT_ERROR = 'APPOINTMENT_ERROR',
  PATIENT_NOT_FOUND = 'PATIENT_NOT_FOUND',
  WORKFLOW_NOT_FOUND = 'WORKFLOW_NOT_FOUND',
  FIRESTORE_DATA_NOT_FOUND = 'FIRESTORE_DATA_NOT_FOUND',
  WORKFLOW_TASK_HAS_ERROR = 'WORKFLOW_TASK_HAS_ERROR',
  UNABLE_TO_SEND_MESSAGE = 'UNABLE_TO_SEND_MESSAGE',
  NO_HIPAA_CONSENT = 'NO_HIPAA_CONSENT',
  UNKNOWN_VARIABLE = 'UNKNOWN_VARIABLE',
  UNKNOWN_VARIABLE_TYPE = 'UNKNOWN_VARIABLE_TYPE',
  INCORRECT_VARIABLE_TYPE = 'INCORRECT_VARIABLE_TYPE',
  WORKFLOW_TASK_EXECUTION_TIME_CONDITION_ERROR = 'WORKFLOW_TASK_EXECUTION_TIME_CONDITION_ERROR',
  WORKFLOW_TASK_ELIGIBILITY_CONDITION_ERROR = 'WORKFLOW_TASK_ELIGIBILITY_CONDITION_ERROR',
  WORKFLOW_TASK_VARIABLE_TRANSFORMATION_ERROR = 'WORKFLOW_TASK_VARIABLE_TRANSFORMATION_ERROR',
  WORKFLOW_TASK_CALLBACKS_ERROR = 'WORKFLOW_TASK_CALLBACKS_ERROR',
  DATETIME_ERROR = 'DATETIME_ERROR',
}

export enum MessageType {
  INTERNAL = 'INTERNAL',
  PATIENT_FACING = 'PATIENT_FACING',
  INFORMATION = 'INFORMATION',
  DEFAULT = '',
}

export enum MessageSenderType {
  PATIENT = 'PATIENT',
  CLINIC = 'CLINIC',
  BOT = 'BOT',
  TWILIO = 'TWILIO',
  DEFAULT = '',
}

export enum MessageStatus {
  ACCEPTED = 'accepted',
  SCHEDULED = 'scheduled',
  QUEUED = 'queued',
  SENDING = 'sending',
  SENT = 'sent',
  DELIVERY_UNKNOWN = 'delivery_unknown',
  DELIVERED = 'delivered',
  UNDELIVERED = 'undelivered',
  FAILED = 'failed',
  DEFAULT = '',
}

export const ChatbotName = 'chatbot';
export const TwilioName = 'twilio';

// Using an extremely old date as our starting point instead of 1 Jan 1970
// so we may not end up with default dates which may be valid birthday for example.
export const DefaultDate = new Date('1900-01-01T00:00:00.000Z');

export enum VariableTypes {
  BOOLEAN = 'Boolean', // maps to boolean
  STRING = 'String', // maps to string
  NUMBER = 'Number', // maps to number
  DATETIME = 'DateTime', // maps to string datetime
  DATE = 'Date', // maps to string datetime
  TIME = 'Time', // maps to string datetime
  DURATION = 'Duration', // maps to number in sec
  SINGLE_SELECT_TEXT = 'SingleSelectText', // maps to string
  MULTI_SELECT_TEXT = 'MultiSelectText', // maps to string[]
  PHONE_NUMBER = 'PhoneNumber', // maps to string
  EMAIL = 'Email', // maps to string
  DEFAULT = '',
}

export enum VariableCollections {
  patientAbout = 'patientAbout',
  patientMedicalDetails = 'patientMedicalDetails',
  workflowSurgeryDetails = 'workflowSurgeryDetails',
  medications = 'medications',
  clinicDetails = 'clinicDetails',
  workflowTasks = 'workflowTasks',
  appointments = 'appointments',
  importPatientsFromCsvVariables = 'importPatientsFromCsvVariables',
}

export enum MedicationConstants {
  beforeSurgery = 'beforeSurgery',
  afterSurgery = 'afterSurgery',
  all = 'all',
  q8h = 'q8h',
  q12h = 'q12h',
  qd = 'qd',
  qpack = 'qpack',
  q8hWithReminders = 'q8hWithReminders',
  q12hWithReminders = 'q12hWithReminders',
  qdWithReminders = 'qdWithReminders',
  qpackWithReminders = 'qpackWithReminders',
  prescriptionDrugs = 'prescriptionDrugs',
  overTheCounterDrugs = 'overTheCounterDrugs',
}

export enum AppointmentConstants {
  name = 'name',
  location = 'location',
  startDateTime = 'startDateTime',
  duration = 'duration',
  type = 'type',
  status = 'status',
  confirmed = 'confirmed',
  cancelled = 'cancelled',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export enum WorkflowBuilderExectionTimeOperators {
  PLUS = 'PLUS',
  WITHIN = 'WITHIN',
  IMMEDIATELY = 'IMMEDIATELY',
  VALUE_ENTERED = 'VALUE_ENTERED',
  VALUE_UPDATED = 'VALUE_UPDATED',
  NOW = 'NOW',
  BETWEEN = 'BETWEEN',
}

export enum WorkflowBuilderTaskEligibilityOperators {
  AND = 'AND',
  OR = 'OR',
  EQUALS = 'EQUALS', // numbers, string, boolean, singleSelect, duration, phoneNum, email
  NOT_EQUALS = 'NOT_EQUALS', // numbers, string, boolean, singleSelect, duration, phoneNum, email
  CONTAINS = 'CONTAINS', // multiselect, medicationNameWithRemainingReminders
  NOT_CONTAINS = 'NOT_CONTAINS', // multiselect, medicationNameWithRemainingReminders
  TRUE = 'TRUE', // independent condition
  VALUE_EXISTS = 'VALUE_EXISTS', // numbers, string, singleSelect, duration, phoneNum, email, multiselect, medicationNameWithRemainingReminders
  VALUE_NOT_EXISTS = 'VALUE_NOT_EXISTS', // numbers, string, singleSelect, duration, phoneNum, email, multiselect,
  EXECUTION_TIME_VALID = 'EXECUTION_TIME_VALID',
  LESS_THAN = 'LESS_THAN', // numbers, duration, date
  GREATER_THAN = 'GREATER_THAN', // numbers, duration, date
  LESS_THAN_EQUALS = 'LESS_THAN_EQUALS', // numbers, duration, date
  GREATER_THAN_EQUALS = 'GREATER_THAN_EQUALS', // numbers, duration, date
}

export enum WorkflowBuilderVariableTransformation {
  USE_VARIABLE = 'USE_VARIABLE', // numbers, string, singleSelect, duration, phoneNum, email, multiselect
  ASSIGN_APPOINTMENT_VARIABLE = 'ASSIGN_APPOINTMENT_VARIABLE', // appointment
  PLUS = 'PLUS', // number, duration, datetime
  EXTRACT_DD_MM_YYYY = 'EXTRACT_DD_MM_YYYY', // datetime, date
  EXTRACT_DAY = 'EXTRACT_DAY', // datetime, date
  EXTRACT_HOUR = 'EXTRACT_HOUR', // datetime, date
  TASK_UPDATED_AT = 'TASK_UPDATED_AT',
  VARIABLE_UPDATED_AT = 'VARIABLE_UPDATED_AT',
  TASK_EXECUTION_TIMESTAMP = 'TASK_EXECUTION_TIMESTAMP',
  START_OF_WEEK = 'START_OF_WEEK',
  TASK_DONE = 'TASK_DONE',
  TASK_NOT_DONE = 'TASK_NOT_DONE',
}

export enum WorkflowTaskCallbacks {
  INCREMENT_MEDICATION_REMINDERS = 'INCREMENT_MEDICATION_REMINDERS',
  AWAIT_APPOINTMENT_CONFIRMATION = 'AWAIT_APPOINTMENT_CONFIRMATION',
  AWAIT_MULTIPLE_APPOINTMENT_CONFIRMATION = 'AWAIT_MULTIPLE_APPOINTMENT_CONFIRMATION',
  AWAIT_APPOINTMENT_REVIEW_BASED_ON_RATING = 'AWAIT_APPOINTMENT_REVIEW_BASED_ON_RATING',
  APPROVE_PATIENT_HIPAA_CONSENT = 'APPROVE_PATIENT_HIPAA_CONSENT',
}

export enum CHATBOT_AUTOMATIC_MSG {
  AWAIT_HIPAA_CONSENT_FOR_SMS = 'AWAIT_HIPAA_CONSENT_FOR_SMS',
  HIPAA_CONSENT_FOR_SMS_RECEIVED = 'HIPAA_CONSENT_FOR_SMS_RECEIVED',
  AWAIT_APPOINTMENT_CONFIRMATION = 'AWAIT_APPOINTMENT_CONFIRMATION',
  AWAIT_MULTIPLE_APPOINTMENT_CONFIRMATION = 'AWAIT_MULTIPLE_APPOINTMENT_CONFIRMATION',
  AWAIT_APPOINTMENT_REVIEW_BASED_ON_RATING = 'AWAIT_APPOINTMENT_REVIEW_BASED_ON_RATING',
  AWAIT_APPOINTMENT_REVIEW_REASON = 'AWAIT_APPOINTMENT_REVIEW_REASON',
  APPOINTMENT_CONFIRMED = 'APPOINTMENT_CONFIRMED',
  APPOINTMENT_CANCELLED = 'APPOINTMENT_CANCELLED',
  APPOINTMENT_RESCHEDULE = 'APPOINTMENT_RESCHEDULE',
  APPOINTMENT_RATED = 'APPOINTMENT_RATED',
  AFFIRM_HIPAA_CONSENT = 'AFFIRM_HIPAA_CONSENT',
  REJECT_HIPAA_CONSENT = 'REJECT_HIPAA_CONSENT',
  STOP_SMS_REQUEST = 'STOP_SMS_REQUEST',
  DEFAULT = '',
}

export enum AppointmentStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  INCORRECT_CONFIRMATION = 'INCORRECT_CONFIRMATION',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  PATIENT_CANCELLED = 'PATIENT_CANCELLED',
  IN_LOBBY = 'IN_LOBBY',
  IN_ROOM = 'IN_ROOM',
  SEEN = 'SEEN',
  NO_SHOW = 'NO_SHOW',
  TENTATIVE = 'TENTATIVE',
  DISMISSED = 'DISMISSED',
  RESCHEDULED = 'RESCHEDULED',
  READY_FOR_CHECKOUT = 'READY_FOR_CHECKOUT',
  SCHEDULED = 'SCHEDULED',
  WAITING = 'WAITING',
  SEATED = 'SEATED',
  KEPT = 'KEPT',
  REMOVED = 'REMOVED',
  DEFAULT = '',
}

export enum WEBHOOK_RESOURCE_TYPES {
  APPOINTMENT = 'Appointment',
  PATIENT = 'Patient',
  DEFAULT = '',
}

export enum WEBHOOK_EVENT {
  APPOINTMENT_CREATED = 'appointment_created',
  APPOINTMENT_UPDATED = 'appointment_updated',
  PATIENT_CREATED = 'patient_created',
  PATIENT_UPDATED = 'patient_updated',
  DEFAULT = '',
}

export enum TAGS {
  PATIENT_CREATED_FROM_EHR = 'PATIENT_CREATED_FROM_EHR',
  PATIENT_UPDATED_FROM_EHR = 'PATIENT_UPDATED_FROM_EHR',
  APPOINTMENT_CREATED_FROM_EHR = 'APPOINTMENT_CREATED_FROM_EHR',
  APPOINTMENT_UPDATED_FROM_EHR = 'APPOINTMENT_UPDATED_FROM_EHR',
}

export enum AppointmentCreationCallbacks {
  HNV_AUTO_ASSIGN_APPOINTMENT = 'HNV_AUTO_ASSIGN_APPOINTMENT',
}

export const mmsSupportedImgFileExts = ['jpg', 'jpeg', 'gif', 'png'];

export enum PatientCsvUploadCallbacks {
  NYSPINE_AUTO_CREATE_PATIENT_AND_ASSIGN_WORKFLOW = 'NYSPINE_AUTO_CREATE_PATIENT_AND_ASSIGN_WORKFLOW',
  RAM_AUTO_CREATE_PATIENT_AND_ASSIGN_WORKFLOW = 'RAM_AUTO_CREATE_PATIENT_AND_ASSIGN_WORKFLOW',
  PRECISION_ENT_AUTO_CREATE_PATIENT_AND_ASSIGN_WORKFLOW = 'PRECISION_ENT_AUTO_CREATE_PATIENT_AND_ASSIGN_WORKFLOW',
  OSI_AUTO_CREATE_PATIENT = 'OSI_AUTO_CREATE_PATIENT',
  MKR_MEDICAL_AUTO_CREATE_PATIENT_AND_ASSIGN_WORKFLOW = 'MKR_MEDICAL_AUTO_CREATE_PATIENT_AND_ASSIGN_WORKFLOW',
  FALCON_SMILES_AUTO_CREATE_PATIENT_AND_ASSIGN_WORKFLOW = 'FALCON_SMILES_AUTO_CREATE_PATIENT_AND_ASSIGN_WORKFLOW',
  TTS_AUTO_CREATE_PATIENT = 'TTS_AUTO_CREATE_PATIENT',
  HNV_AUTO_CREATE_PATIENT_AND_ASSIGN_WORKFLOW = 'HNV_AUTO_CREATE_PATIENT_AND_ASSIGN_WORKFLOW',
}

export enum AcuitySchedulingActions {
  SCHEDULED = 'scheduled',
  RESCHEDULED = 'rescheduled',
  CANCELED = 'canceled',
}

export enum AppointmentRequestStatus {
  SUBMITTED = 'submitted',
  DONE = 'done',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  RESCHEDULED = 'rescheduled',
  DEFAULT = '',
}
