import React, { FunctionComponent } from 'react';
import { WebchatWidget } from '../components/WebchatWidget';

interface WebchatWidgetContainerProps {}

export const WebchatWidgetContainer: FunctionComponent<
  WebchatWidgetContainerProps
> = () => {
  return <WebchatWidget />;
};
