import {
  doc,
  FirestoreError,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';
import { fb } from '../config/FirebaseConfig';
import { FirestoreCollections } from '../shared/Constants';
import { clinicDetailsConverter } from '../shared/FirestoreConvertors';
import { ClinicDetails } from '../shared/FirestoreInterfaces';

export const listenToClinicDetails = (
  onNext: (clinicDetails: ClinicDetails | undefined) => void,
  onError: (error: FirestoreError) => void
) => {
  const db = getFirestore(fb);
  const clinicDetailsDoc = doc(
    db,
    FirestoreCollections.CONFIGS,
    FirestoreCollections.CLINIC_DETAILS
  ).withConverter(clinicDetailsConverter);

  const unsubscribe = onSnapshot(
    clinicDetailsDoc,
    (docSnapshot) => {
      if (docSnapshot.exists()) {
        onNext(docSnapshot.data());
      } else {
        onNext(undefined);
      }
    },
    (error) => {
      onError(error);
    }
  );

  return unsubscribe;
};
