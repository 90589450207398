import { Box, Paper, Typography } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { WebchatWidgetForm } from './WebchatWidgetForm';
import { listenToClinicDetails } from '../services/ConfigsServices';
import {
  ClinicDetails,
  defaultClinicDetails,
} from '../shared/FirestoreInterfaces';
import { formatUSPhoneNumber } from '../utilities/PhoneUtils';

interface WebchatWidgetPopupProps {}

enum PAGE {
  FORM = 'FORM',
  SUBMIT_CONFIRMATION = 'SUBMIT_CONFIRMATION',
}

export const WebchatWidgetPopup: FunctionComponent<WebchatWidgetPopupProps> = (
  props
) => {
  const [page, setPage] = useState<PAGE>(PAGE.FORM);
  const [clinicDetails, setClinicDetails] = useState<ClinicDetails>({
    ...defaultClinicDetails,
  });

  useEffect(() => {
    const unubscribe = listenToClinicDetails(
      (clinicDetails) => {
        if (clinicDetails) {
          setClinicDetails(clinicDetails);
        } else {
          setClinicDetails({ ...defaultClinicDetails });
        }
      },
      (error) => {
        console.log(JSON.stringify(error, null, 2));
        setClinicDetails({ ...defaultClinicDetails });
      }
    );

    return unubscribe;
  }, []);

  const onSubmit = () => {
    setPage(PAGE.SUBMIT_CONFIRMATION);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        width: '95%',
        height: '95%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.webchatWidget.backgroundColor,
          color: (theme) => theme.webchatWidget.contrastColor,
          padding: 2,
          borderTopLeftRadius: 'inherit',
          borderTopRightRadius: 'inherit',
        }}
      >
        <Typography variant={'h5'}>Hello!</Typography>
        <Typography variant={'body2'} sx={{ fontWeight: 'regular' }}>
          What can we help you with?
        </Typography>
      </Box>
      {page === PAGE.FORM && (
        <Box sx={{ marginTop: 2, overflowY: 'auto' }}>
          {/* slight left side placement for first message */}
          <Typography
            variant={'body2'}
            sx={{
              backgroundColor: (theme) =>
                theme.webchatWidget.fromThemBackground,
              borderRadius: 2,
              padding: 1,
              marginLeft: 1,
              marginRight: 5,
            }}
          >
            Please enter your information and we'll send you a text.
          </Typography>
          {/* slight right side indent for second message, i.e. form */}
          <Box
            sx={{
              marginTop: 2,
              marginLeft: 5,
              marginRight: 1,
            }}
          >
            <WebchatWidgetForm onSubmit={onSubmit} />
          </Box>
        </Box>
      )}
      {page === PAGE.SUBMIT_CONFIRMATION && (
        <Box
          sx={{
            backgroundColor: (theme) => theme.webchatWidget.fromThemBackground,
            borderRadius: 2,
            padding: 1,
            marginLeft: 2,
            marginRight: 2,
            marginTop: 2,
          }}
        >
          <Typography variant='body2'>We got your message.</Typography>
          <Typography variant='body2'>
            {`Our team will be texting you back from the number ${formatUSPhoneNumber(
              clinicDetails.clinicWebChatNum
            )}.`}
          </Typography>
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}></Box>
      <Typography
        variant={'caption'}
        sx={{
          textAlign: 'center',
          backgroundColor: (theme) => theme.webchatWidget.fromThemBackground,
          width: '100%',
          borderBottomLeftRadius: 'inherit',
          borderBottomRightRadius: 'inherit',
        }}
      >
        We run on Confido Health
      </Typography>
    </Paper>
  );
};
