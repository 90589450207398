import { fb } from '../config/FirebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  CaptureMessageFromWebChatParams,
  CaptureMessageFromWebChatResult,
} from '../shared/CloudFunctionTypes';

export const createFunction = <T = any, R = any>(
  name: string,
  region?: string
): ((data: T) => Promise<R>) => {
  let functions = getFunctions(fb);
  if (region) {
    functions = getFunctions(fb, region);
  }
  const callable = httpsCallable<T, R>(functions, name, { timeout: 1000000 });
  return async (data: T) => (await callable(data)).data;
};

export const captureMessageFromWebChatCallable = createFunction<
  CaptureMessageFromWebChatParams,
  CaptureMessageFromWebChatResult
>('captureMessageFromWebChatCallable', 'us-central1');
