import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  darken,
} from '@mui/material';
import { useFormik } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import React, { FunctionComponent, useState } from 'react';
import * as yup from 'yup';
import { captureMessageFromWebChatCallable } from '../services/FirebaseFunctions';
import { splitName } from '../utilities/NameUtils';

interface WebchatWidgetFormProps {
  onSubmit: () => void;
}

const valiationSchema = yup.object({
  name: yup.string().required('Name is required.'),
  phoneNumber: yup.string().nullable().required('Phone number is required.'),
  email: yup.string().required('Email is required.'),
  message: yup.string().required('Message is required.'),
});

export const WebchatWidgetForm: FunctionComponent<WebchatWidgetFormProps> = (
  props
) => {
  const { onSubmit } = props;
  const [submitting, setSubmitting] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      phoneNumber: '',
      email: '',
      message: '',
    },
    onSubmit: async (values) => {
      setSubmitting(true);

      captureMessageFromWebChatCallable({
        firstName: splitName(values.name).firstName,
        lastName: splitName(values.name).lastName,
        phoneNum: values.phoneNumber,
        email: values.email,
        message: values.message,
      })
        .then(({ errorMessage }) => {
          if (errorMessage) {
            console.log('ERROR: ', JSON.stringify(errorMessage, null, 2));
          }
          setSubmitting(false);
          onSubmit();
        })
        .catch((error) => {
          console.log('ERROR: ', error);
          setSubmitting(false);
          onSubmit();
        });
    },
    validationSchema: valiationSchema,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.webchatWidget.fromMeBackground,
          borderRadius: 2,
          padding: 1,
        }}
      >
        <TextField
          variant='outlined'
          margin='dense'
          fullWidth
          required
          label={'Your name'}
          name={'name'}
          value={formik.values.name}
          onChange={formik.handleChange}
          InputProps={{
            sx: {
              fontSize: (theme) => theme.typography.body2.fontSize,
            },
          }}
          sx={{
            '.MuiInputLabel-root': {
              fontSize: (theme) => theme.typography.body2.fontSize,
            },
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <MuiTelInput
          variant='outlined'
          fullWidth
          required
          margin='dense'
          label='Phone Number'
          name='phoneNumber'
          value={formik.values.phoneNumber}
          onChange={(value, info) =>
            formik.setFieldValue('phoneNumber', info.numberValue, true)
          }
          InputProps={{
            sx: {
              fontSize: (theme) => theme.typography.body2.fontSize,
            },
          }}
          sx={{
            '.MuiInputLabel-root': {
              fontSize: (theme) => theme.typography.body2.fontSize,
            },
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
          }
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          preferredCountries={['US']}
          defaultCountry='US'
        />
        <TextField
          variant='outlined'
          margin='dense'
          fullWidth
          required
          label={'Your email'}
          name={'email'}
          type='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          InputProps={{
            sx: {
              fontSize: (theme) => theme.typography.body2.fontSize,
            },
          }}
          sx={{
            '.MuiInputLabel-root': {
              fontSize: (theme) => theme.typography.body2.fontSize,
            },
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          variant='outlined'
          fullWidth
          margin='dense'
          required
          multiline
          label='Type your message'
          name='message'
          minRows={1}
          value={formik.values.message}
          InputProps={{
            sx: {
              fontSize: (theme) => theme.typography.body2.fontSize,
            },
          }}
          sx={{
            '.MuiInputLabel-root': {
              fontSize: (theme) => theme.typography.body2.fontSize,
            },
          }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
        />
      </Box>
      <Button
        variant='contained'
        type='submit'
        sx={{
          marginTop: 2,
          backgroundColor: (theme) => theme.webchatWidget.backgroundColor,
          '&:hover': {
            backgroundColor: (theme) =>
              darken(theme.webchatWidget.backgroundColor, 0.2),
          },
        }}
      >
        {submitting ? <CircularProgress size='1rem' /> : 'Submit'}
      </Button>
      <Typography variant={'caption'}>
        Message and data rates may apply.
      </Typography>
    </form>
  );
};
