import {
  AccessLevel,
  Gender,
  MedicationFrequency,
  MessageSenderType,
  MessageType,
  VariableTypes,
  DefaultDate,
  CHATBOT_AUTOMATIC_MSG,
  AppointmentStatus,
  MessageStatus,
  AppointmentRequestStatus,
} from './Constants';

export interface FeatureFlags {
  skipHipaaComplianceMessageOnPatientCreate: boolean;
  skipHipaaComplianceMessageOnPatientUnarchive: boolean;
  enableWebchat: boolean;
  enableTwoWayTexting: boolean;
  enableCsvBasedPatientImport: boolean;
  enableChatbotAutoReplies: boolean;
}

export const defaultFeatureFlags: FeatureFlags = {
  skipHipaaComplianceMessageOnPatientCreate: false,
  skipHipaaComplianceMessageOnPatientUnarchive: false,
  enableWebchat: false,
  enableTwoWayTexting: false,
  enableCsvBasedPatientImport: false,
  enableChatbotAutoReplies: false,
};

export interface LanguageSetting {
  language: string;
  noReplyMessage: string;
  hipaaMessage: string;
  hipaaReceivedMessage: string;
  hipaaStopMessage: string;
  hipaaRejectedMessage: string;
  hipaaReplyNotUnderstood: string;
  smsStopMessage: string;
  smsRestartMessage: string;
  appointmentConfirmedResponse: string;
  appointmentCancelledResponse: string;
  appointmentRescheduleResponse: string;
  appointmentResponseNotUnderstood: string;
  goodReviewResponse: string;
  badReviewResponse: string;
  reviewResponseNotUnderstood: string;
  capturedReviewReason: string;
  hipaaMessageResend: string;
}

export const defaultLanguageSetting: LanguageSetting = {
  language: '',
  noReplyMessage: '',
  hipaaMessage: '',
  hipaaReceivedMessage: '',
  hipaaStopMessage: '',
  hipaaRejectedMessage: '',
  hipaaReplyNotUnderstood: '',
  smsStopMessage: '',
  smsRestartMessage: '',
  appointmentConfirmedResponse: '',
  appointmentCancelledResponse: '',
  appointmentRescheduleResponse: '',
  appointmentResponseNotUnderstood: '',
  goodReviewResponse: '',
  badReviewResponse: '',
  reviewResponseNotUnderstood: '',
  capturedReviewReason: '',
  hipaaMessageResend: '',
};

export interface ClinicDetails {
  clinicName: string;
  doctorPhoneNum: string;
  clinicPhoneNum: string;
  clinicSmsNum: string;
  clinicWebChatNum: string;
  supportHtml: string;
  timeZone: string;
  webChatUrl: string;
  chatUrl: string;
  ehrConnectorBaseUrl: string;
  locationId: string;
  subDomain: string;
  gcpProjectId: string;
  languageSettings: LanguageSetting[];
  appointmentCreatedCallbacks: string[];
  maxHipaaRemindersCount: number;
  hipaaMessageResendIntervalMinutes: number;
  patientCsvUploadedCallbacks: string;
  patientCreationWorkflowUid: string;
}

export const defaultClinicDetails: ClinicDetails = {
  clinicName: '',
  doctorPhoneNum: '',
  clinicPhoneNum: '',
  clinicSmsNum: '',
  clinicWebChatNum: '',
  supportHtml: '',
  languageSettings: [],
  timeZone: '',
  webChatUrl: '',
  chatUrl: '',
  ehrConnectorBaseUrl: '',
  locationId: '',
  subDomain: '',
  gcpProjectId: '',
  appointmentCreatedCallbacks: [],
  maxHipaaRemindersCount: 0,
  hipaaMessageResendIntervalMinutes: 0,
  patientCsvUploadedCallbacks: '',
  patientCreationWorkflowUid: '',
};

export interface Variable {
  name: string;
  title: string;
  type: VariableTypes;
  defaultValue: string | number | string[] | boolean;
  value: string | number | string[] | boolean;
  valueOptions: string[];
  transformations: string[];
  isRequired: boolean;
  isVisible: boolean;
  isDepricated: boolean;
  createdAt: string;
  lastUpdatedAt: string;
}

export const defaultVariable: Variable = {
  name: '',
  title: '',
  type: VariableTypes.DEFAULT,
  defaultValue: '',
  value: '',
  valueOptions: [],
  transformations: [],
  isRequired: false,
  isVisible: false,
  isDepricated: false,
  createdAt: DefaultDate.toISOString(),
  lastUpdatedAt: DefaultDate.toISOString(),
};

export interface FormVariables {
  patientMedicalDetails: Variable[];
  appointmentVariables: Variable[];
  importPatientsFromCsvVariables: Variable[];
}

export const defaultFormVariables: FormVariables = {
  patientMedicalDetails: [],
  appointmentVariables: [],
  importPatientsFromCsvVariables: [],
};

export interface Provider {
  providerUid: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNum: string;
  accessLevel: AccessLevel;
  createdAt: string; // datetime
  createdBy: string;
  lastUpdatedBy: string;
  lastUpdatedAt: string; // datetime
}

export const defaultProvider: Provider = {
  providerUid: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNum: '',
  accessLevel: AccessLevel.MEMBER,
  createdAt: DefaultDate.toISOString(),
  createdBy: '',
  lastUpdatedBy: '',
  lastUpdatedAt: DefaultDate.toISOString(),
};

export interface WorkflowSummary {
  workflowUid: string;
  workflowName: string;
  totalTasks: number;
  tasksPending: number;
  tasksDone: number;
  tasksInErrorState: number;
  createdAt: string;
}

export const defaultWorkflowSummary: WorkflowSummary = {
  workflowUid: '',
  workflowName: '',
  totalTasks: 0,
  tasksPending: 0,
  tasksDone: 0,
  tasksInErrorState: 0,
  createdAt: DefaultDate.toISOString(),
};

export interface PatientAbout {
  firstName: string;
  lastName: string;
  phoneNum: string;
  email: string;
  gender: Gender;
  dob: string;
  address: string;
  preferredLanguage: string;
}

export const defaultPatientAbout: PatientAbout = {
  firstName: '',
  lastName: '',
  phoneNum: '',
  email: '',
  gender: Gender.DEFAULT,
  dob: DefaultDate.toISOString(),
  address: '',
  preferredLanguage: '',
};

export interface PatientHipaaStatus {
  hipaaComplianceForSmsRecieved: boolean;
  hipaaComplianceForSmsRejected: boolean;
  hipaaComplianceForSmsStopped: boolean;
}

export const defaultPatientHipaaStatus: PatientHipaaStatus = {
  hipaaComplianceForSmsRecieved: false,
  hipaaComplianceForSmsRejected: false,
  hipaaComplianceForSmsStopped: false,
};

export interface MmsMedia {
  extension: string;
  mediaUrl: string;
  mediaSid: string;
}

export const defaultMmsMedia: MmsMedia = {
  extension: '',
  mediaUrl: '',
  mediaSid: '',
};

export interface Message {
  messageUid: string;
  senderType: MessageSenderType;
  messageType: MessageType;
  senderUid: string;
  smsMessageSid: string;
  patientUid: string;
  senderPhoneNum: string;
  recieverPhoneNum: string;
  messageBody: string;
  mmsMedia: MmsMedia[];
  status: MessageStatus;
  errorCode: string;
  errorMessage: string;
  createdAt: string;
  createdBy: string;
  lastUpdatedBy: string;
  lastUpdatedAt: string; // datetime
}

export const defaultMessage: Message = {
  messageUid: '',
  senderType: MessageSenderType.DEFAULT,
  messageType: MessageType.DEFAULT,
  senderUid: '',
  smsMessageSid: '',
  patientUid: '',
  senderPhoneNum: '',
  recieverPhoneNum: '',
  messageBody: '',
  mmsMedia: [],
  status: MessageStatus.DEFAULT,
  errorCode: '',
  errorMessage: '',
  createdAt: DefaultDate.toISOString(),
  createdBy: '',
  lastUpdatedBy: '',
  lastUpdatedAt: DefaultDate.toISOString(),
};

export interface ConversationInfo {
  lastMessage: Message;
  firstMessageUid: string;
  lastHealthcareProviderMsgTime: string;
  chatbotState: string;
  hipaaRemindersSent: number;
  isSmsServiceDisabled: boolean;
  isRead: boolean;
}

export const defaultConversationInfo: ConversationInfo = {
  lastMessage: { ...defaultMessage },
  firstMessageUid: '',
  lastHealthcareProviderMsgTime: DefaultDate.toISOString(),
  chatbotState: CHATBOT_AUTOMATIC_MSG.DEFAULT,
  hipaaRemindersSent: 0,
  isSmsServiceDisabled: false,
  isRead: true,
};

export interface Patient {
  patientUid: string;
  ehrUid: number;
  MRN: string;
  about: PatientAbout;
  medicalDetails: Variable[];
  hipaaStatus: PatientHipaaStatus;
  currentAssignedWorkflows: WorkflowSummary[];
  currentAssignedWorkflowUid: string;
  currentAssignedWorkflowName: string;
  createdAt: string; // datetime
  createdBy: string;
  lastUpdatedBy: string;
  lastUpdatedAt: string; // datetime
  isArchived: boolean;
  conversationInfo: ConversationInfo;
}

export const defaultPatient: Patient = {
  patientUid: '',
  ehrUid: 0,
  MRN: '',
  about: defaultPatientAbout,
  medicalDetails: [],
  hipaaStatus: { ...defaultPatientHipaaStatus },
  currentAssignedWorkflows: [],
  currentAssignedWorkflowUid: '',
  currentAssignedWorkflowName: '',
  createdAt: DefaultDate.toISOString(),
  createdBy: '',
  lastUpdatedBy: '',
  lastUpdatedAt: DefaultDate.toISOString(),
  isArchived: false,
  conversationInfo: { ...defaultConversationInfo },
};

export interface Medication {
  name: string;
  frequency: MedicationFrequency;
  numOfTotalDoses: number;
  isPrescriptionDrug: boolean;
  numOfRemindersSent: number;
}

export const defaultMedication: Medication = {
  name: '',
  frequency: MedicationFrequency.DEFAULT,
  numOfTotalDoses: 0,
  isPrescriptionDrug: false,
  numOfRemindersSent: 0,
};

export interface WorkflowTask {
  workflowTaskUid: number;
  name: string;
  timeline: string;
  executionTimeCondition: string;
  executionTimestamp: string;
  lastExecutionTimestamp: string;
  patientMessageContentWithPlaceholders: string;
  patientMessageContent: string;
  taskEligibilityCondition: string[];
  isEligibleMessage: boolean;
  isSendMessage: boolean;
  isTaskDone: boolean;
  hasError: boolean;
  errorMessages: string[];
  totalRepeatations: number;
  repeatationsDone: number;
  repeatationIntervalSec: number;
  callbacks: string[];
  createdAt: string; // datetime
  createdBy: string;
  lastUpdatedBy: string;
  lastUpdatedAt: string; // datetime
  messageSentBy: string;
}

export const defaultWorkflowTask: WorkflowTask = {
  workflowTaskUid: 0,
  name: '',
  timeline: '',
  executionTimeCondition: '',
  executionTimestamp: DefaultDate.toISOString(),
  lastExecutionTimestamp: DefaultDate.toISOString(),
  patientMessageContentWithPlaceholders: '',
  patientMessageContent: '',
  taskEligibilityCondition: [],
  isEligibleMessage: false,
  isSendMessage: true,
  isTaskDone: false,
  hasError: false,
  errorMessages: [],
  totalRepeatations: 0,
  repeatationsDone: 0,
  repeatationIntervalSec: 0,
  callbacks: [],
  createdAt: DefaultDate.toISOString(),
  createdBy: '',
  lastUpdatedBy: '',
  lastUpdatedAt: DefaultDate.toISOString(),
  messageSentBy: '',
};

export interface SurgeryDetails {
  variables: Variable[];
  medicationBeforeSurgery: Medication[];
  medicationAfterSurgery: Medication[];
}

export const defaultSurgeryDetails: SurgeryDetails = {
  variables: [],
  medicationBeforeSurgery: [],
  medicationAfterSurgery: [],
};

export interface Workflow {
  workflowUid: string;
  templateWorkflowUid: string;
  workflowName: string;
  assignedPatientUid: string;
  assignedPatientPhoneNum: string;
  isActiveWorkflow: boolean;
  skipHipaaCompliance: boolean;
  skipWorkflowApptStatusDiffCheck: boolean;
  surgeryDetails: SurgeryDetails;
  workflowTasks: WorkflowTask[];
  createdAt: string; // datetime
  createdBy: string;
  lastUpdatedBy: string;
  lastUpdatedAt: string; // datetime
}

export const defaultWorkflow: Workflow = {
  workflowUid: '',
  templateWorkflowUid: '',
  workflowName: '',
  assignedPatientUid: '',
  assignedPatientPhoneNum: '',
  isActiveWorkflow: false,
  skipHipaaCompliance: false,
  skipWorkflowApptStatusDiffCheck: false,
  surgeryDetails: { ...defaultSurgeryDetails },
  workflowTasks: [],
  createdAt: DefaultDate.toISOString(),
  createdBy: '',
  lastUpdatedBy: '',
  lastUpdatedAt: DefaultDate.toISOString(),
};

export interface TwilioConfigs {
  accountSid: string;
  authToken: string;
  statusCallback: string;
  chatMessagingServiceSid: string;
  webchatMessagingServiceSid: string;
}

export const defaultTwilioConfigs: TwilioConfigs = {
  accountSid: '',
  authToken: '',
  statusCallback: '',
  chatMessagingServiceSid: '',
  webchatMessagingServiceSid: '',
};

export interface MailModoConfigs {
  apiKey: string;
  webChatCampaignId: string;
  webChatReceiverEmails: string[];
  patientReplyCampaignId: string;
  patientReplyReceiverEmails: string[];
  patientAppointmentReplyCampaignId: string;
  patientAppointmentReplyEmails: string[];
  patientReviewReasonCampaignId: string;
  patientReviewReasonEmails: string[];
  patientAppointmentRescheduleCampaignId: string;
  patientAppointmentRescheduleEmails: string[];
  voiceCallNotificationCampaignId: string;
  voiceCallNotificationEmails: string[];
}

export const defaultMailModoConfigs: MailModoConfigs = {
  apiKey: '',
  webChatCampaignId: '',
  webChatReceiverEmails: [],
  patientReplyCampaignId: '',
  patientReplyReceiverEmails: [],
  patientAppointmentReplyCampaignId: '',
  patientAppointmentReplyEmails: [],
  patientReviewReasonCampaignId: '',
  patientReviewReasonEmails: [],
  patientAppointmentRescheduleCampaignId: '',
  patientAppointmentRescheduleEmails: [],
  voiceCallNotificationCampaignId: '',
  voiceCallNotificationEmails: [],
};

export interface Appointment {
  appointmentUid: string;
  appointmentConnectorUid: string;
  patientUid: string;
  patientEhrUid: string;
  ehrUid: number;
  bookedInEhr: boolean;
  appointmentVariables: Variable[];
  saraAppointmentStatus: AppointmentStatus;
  startDateTime: string;
  createdBy: string;
  createdAt: string;
  lastUpdatedAt: string;
  lastUpdatedBy: string;
}

export const defaultAppointment: Appointment = {
  appointmentUid: '',
  appointmentConnectorUid: '',
  patientUid: '',
  patientEhrUid: '',
  ehrUid: 0,
  bookedInEhr: false,
  appointmentVariables: [],
  saraAppointmentStatus: AppointmentStatus.DEFAULT,
  startDateTime: new Date(0).toISOString(),
  createdBy: '',
  createdAt: DefaultDate.toISOString(),
  lastUpdatedAt: DefaultDate.toISOString(),
  lastUpdatedBy: '',
};

export interface MedicationCollectionObject extends Medication {
  medicationUid: string;
  createdAt: string; // datetime
  createdBy: string;
  lastUpdatedAt: string; // datetime
  lastUpdatedBy: string;
}

export const defaultMedicationCollectionObject: MedicationCollectionObject = {
  ...defaultMedication,
  medicationUid: '',
  createdAt: DefaultDate.toISOString(),
  createdBy: '',
  lastUpdatedAt: DefaultDate.toISOString(),
  lastUpdatedBy: '',
};

export interface AcuityCalendarRoutes {
  calendarId: string;
  gcpProjectId: string;
}

export const defaultAcuityCalendarRoutes: AcuityCalendarRoutes = {
  calendarId: '',
  gcpProjectId: '',
};

export interface AcuityConfigs {
  apiKey: string;
  calendarRoutes: AcuityCalendarRoutes[];
}

export const defaultAcuityConfigs: AcuityConfigs = {
  apiKey: '',
  calendarRoutes: [],
};

export interface AppointmentRequest {
  appointmentRequestUid: string;
  bookingUid: string;
  calendarId: string;
  startDateTime: string;
  endDateTime: string;
  requestStatus: AppointmentRequestStatus;
  patientFirstName: string;
  patientLastName: string;
  patientPhoneNum: string;
  patientEmail: string;
  createdBy: string;
  createdAt: string;
  lastUpdatedAt: string;
  lastUpdatedBy: string;
}

export const defaultAppointmentRequest: AppointmentRequest = {
  appointmentRequestUid: '',
  bookingUid: '',
  calendarId: '',
  startDateTime: '',
  endDateTime: '',
  requestStatus: AppointmentRequestStatus.DEFAULT,
  patientFirstName: '',
  patientLastName: '',
  patientPhoneNum: '',
  patientEmail: '',
  createdBy: '',
  createdAt: DefaultDate.toISOString(),
  lastUpdatedAt: DefaultDate.toISOString(),
  lastUpdatedBy: '',
};

export interface VoiceCall {
  voiceCallUid: string;
  voiceCallSid: string;
  direction: string;
  callerPhoneNum: string;
  calledPhoneNum: string;
  callStatus: string;
  createdAt: string;
  createdBy: string;
  lastUpdatedBy: string;
  lastUpdatedAt: string; // datetime
}

export const defaultVoiceCall: VoiceCall = {
  voiceCallUid: '',
  voiceCallSid: '',
  direction: '',
  callerPhoneNum: '',
  calledPhoneNum: '',
  callStatus: '',
  createdAt: DefaultDate.toISOString(),
  createdBy: '',
  lastUpdatedBy: '',
  lastUpdatedAt: DefaultDate.toISOString(),
};
