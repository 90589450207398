import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    webchatWidget: {
      backgroundColor: string;
      fromThemBackground: string;
      fromMeBackground: string;
      contrastColor: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    webchatWidget?: {
      backgroundColor?: string;
      fromThemBackground?: string;
      fromMeBackground?: string;
      contrastColor?: string;
    };
  }
}

export const muiTheme = createTheme({
  typography: {
    fontFamily: ['Inter', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(
      ','
    ),
  },
  webchatWidget: {
    backgroundColor: 'rgb(165, 25, 46)',
    contrastColor: 'white',
    fromThemBackground: '#e5e5ea',
    fromMeBackground: '#e5e5ea88',
  },
});
