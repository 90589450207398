import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  connectFirestoreEmulator,
  FirestoreSettings,
  getFirestore,
  initializeFirestore,
} from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import Cypress from 'cypress';

declare global {
  interface Window {
    Cypress?: Cypress.Cypress;
  }
}

const firebaseConfig = {
  apiKey: 'AIzaSyD9Za_Gbthr1dktB1sOsFkyi8UL0GSh9ks',
  authDomain: 'clinical-workflow-hhne.firebaseapp.com',
  projectId: 'clinical-workflow-hhne',
  storageBucket: 'clinical-workflow-hhne.appspot.com',
  messagingSenderId: '34930813358',
  appId: '1:34930813358:web:f1cb9d92dd796ab6a7803f',
  measurementId: 'G-BN2XMJPQVP',
};

export const fb = initializeApp(firebaseConfig);
export const fbAnalytics = getAnalytics(fb);

const { REACT_APP_FIRESTORE_EMULATOR_HOST, REACT_APP_FUNCTIONS_EMULATOR_HOST } =
  process.env;

// Setup Firestore for cypress
const firestoreSettings: FirestoreSettings = {};
if (window.Cypress) {
  // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
  firestoreSettings.experimentalForceLongPolling = true;
}
initializeFirestore(fb, firestoreSettings);

// Emulate Firestore if REACT_APP_FIRESTORE_EMULATOR_HOST exists in environment
if (REACT_APP_FIRESTORE_EMULATOR_HOST) {
  connectFirestoreEmulator(
    getFirestore(fb),
    'localhost',
    Number(REACT_APP_FIRESTORE_EMULATOR_HOST)
  );

  console.log(
    `Using Firestore emulator: ${Number(REACT_APP_FIRESTORE_EMULATOR_HOST)}`
  );
}

// Emulate Functions if REACT_APP_FUNCTIONS_EMULATOR_HOST exists in environment
if (REACT_APP_FUNCTIONS_EMULATOR_HOST) {
  connectFunctionsEmulator(
    getFunctions(fb),
    'localhost',
    Number(REACT_APP_FUNCTIONS_EMULATOR_HOST)
  );

  console.log(
    `Using Functions emulator: ${Number(REACT_APP_FUNCTIONS_EMULATOR_HOST)}`
  );
}
