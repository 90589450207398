export const splitName = (name: string) => {
  // Trim any leading or trailing whitespace
  name = name.trim();

  // Split the name into an array of words
  const nameArray = name.split(' ');

  // The first word is the first name
  const firstName = nameArray[0];

  // The remaining words are the last name
  const lastName = nameArray.slice(1).join(' ');

  // Return an object with first name and last name properties
  return {
    firstName: firstName,
    lastName: lastName,
  };
};
