import { BackendErrorType } from './Constants';

import { CustomError } from 'ts-custom-error';

export class BackendError extends CustomError {
  constructor(message: string, type: BackendErrorType, description?: {}) {
    super(message);
    // Set name explicitly as minification can mangle class names
    Object.defineProperty(this, 'name', { value: `BackendError` });
    Object.defineProperty(this, 'type', { value: type });
    Object.defineProperty(this, 'description', {
      value: JSON.stringify(description),
    });
  }
}
