import { BackendError } from './Errors';
import { BackendErrorType, VariableTypes } from './Constants';
import {
  ClinicDetails,
  ConversationInfo,
  defaultClinicDetails,
  defaultConversationInfo,
  defaultFormVariables,
  defaultMailModoConfigs,
  defaultLanguageSetting,
  defaultMedication,
  defaultMessage,
  defaultMmsMedia,
  defaultPatient,
  defaultPatientAbout,
  defaultPatientHipaaStatus,
  defaultProvider,
  defaultSurgeryDetails,
  defaultTwilioConfigs,
  defaultVariable,
  defaultWorkflow,
  defaultWorkflowTask,
  FormVariables,
  MailModoConfigs,
  LanguageSetting,
  Medication,
  Message,
  MmsMedia,
  Patient,
  PatientAbout,
  PatientHipaaStatus,
  Provider,
  SurgeryDetails,
  TwilioConfigs,
  Variable,
  Workflow,
  WorkflowTask,
  Appointment,
  defaultAppointment,
  FeatureFlags,
  defaultFeatureFlags,
  MedicationCollectionObject,
  defaultMedicationCollectionObject,
  WorkflowSummary,
  defaultWorkflowSummary,
  defaultAcuityConfigs,
  AcuityConfigs,
  AcuityCalendarRoutes,
  defaultAcuityCalendarRoutes,
  AppointmentRequest,
  defaultAppointmentRequest,
  VoiceCall,
  defaultVoiceCall,
} from './FirestoreInterfaces';
import {
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';

export const featureFlagsConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultFeatureFlags };
  if (!data) {
    return defaults;
  }

  return {
    skipHipaaComplianceMessageOnPatientCreate:
      data.skipHipaaComplianceMessageOnPatientCreate ??
      defaults.skipHipaaComplianceMessageOnPatientCreate,
    skipHipaaComplianceMessageOnPatientUnarchive:
      data.skipHipaaComplianceMessageOnPatientUnarchive ??
      defaults.skipHipaaComplianceMessageOnPatientUnarchive,
    enableWebchat: data.enableWebchat ?? defaults.enableWebchat,
    enableTwoWayTexting:
      data.enableTwoWayTexting ?? defaults.enableTwoWayTexting,
    enableCsvBasedPatientImport:
      data.enableCsvBasedPatientImport ?? defaults.enableCsvBasedPatientImport,
    enableChatbotAutoReplies:
      data.enableChatbotAutoReplies ?? defaults.enableChatbotAutoReplies,
  };
};

export const featureFlagsConverter: FirestoreDataConverter<FeatureFlags> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>
  ): FeatureFlags => {
    const data = snapshot.data();
    return featureFlagsConverterFromfirestore(data);
  },
  toFirestore: (data: FeatureFlags): DocumentData => {
    return {
      ...data,
    };
  },
};

export const languageSettingConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultLanguageSetting };
  if (!data) {
    return defaults;
  }

  return {
    language: data.language ?? defaults.language,
    noReplyMessage: data.noReplyMessage ?? defaults.noReplyMessage,
    hipaaMessage: data.hipaaMessage ?? defaults.hipaaMessage,
    hipaaReceivedMessage:
      data.hipaaReceivedMessage ?? defaults.hipaaReceivedMessage,
    hipaaStopMessage: data.hipaaStopMessage ?? defaults.hipaaStopMessage,
    hipaaRejectedMessage:
      data.hipaaRejectedMessage ?? defaults.hipaaRejectedMessage,
    hipaaReplyNotUnderstood:
      data.hipaaReplyNotUnderstood ?? defaults.hipaaReplyNotUnderstood,
    smsStopMessage: data.smsStopMessage ?? defaults.smsStopMessage,
    smsRestartMessage: data.smsRestartMessage ?? defaults.smsRestartMessage,
    appointmentConfirmedResponse:
      data.appointmentConfirmedResponse ??
      defaults.appointmentConfirmedResponse,
    appointmentCancelledResponse:
      data.appointmentCancelledResponse ??
      defaults.appointmentCancelledResponse,
    appointmentRescheduleResponse:
      data.appointmentRescheduleResponse ??
      defaults.appointmentRescheduleResponse,
    appointmentResponseNotUnderstood:
      data.appointmentResponseNotUnderstood ??
      defaults.appointmentResponseNotUnderstood,
    hipaaMessageResend: data.hipaaMessageResend ?? defaults.hipaaMessageResend,
    goodReviewResponse: data.goodReviewResponse ?? defaults.goodReviewResponse,
    badReviewResponse: data.badReviewResponse ?? defaults.badReviewResponse,
    reviewResponseNotUnderstood:
      data.reviewResponseNotUnderstood ?? defaults.reviewResponseNotUnderstood,
    capturedReviewReason:
      data.capturedReviewReason ?? defaults.capturedReviewReason,
  };
};

export const languageSettingConverter: FirestoreDataConverter<LanguageSetting> =
  {
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<DocumentData>
    ): LanguageSetting => {
      const data = snapshot.data();
      return languageSettingConverterFromfirestore(data);
    },
    toFirestore: (data: LanguageSetting): DocumentData => {
      return {
        ...data,
      };
    },
  };

export const clinicDetailsConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultClinicDetails };
  if (!data) {
    return defaults;
  }

  let languageSettings: LanguageSetting[] = [];
  if (data.languageSettings) {
    data.languageSettings.forEach((setting: any) =>
      languageSettings.push(languageSettingConverterFromfirestore(setting))
    );
  }

  return {
    clinicName: data.clinicName ?? defaults.clinicName,
    doctorPhoneNum: data.doctorPhoneNum ?? defaults.doctorPhoneNum,
    clinicPhoneNum: data.clinicPhoneNum ?? defaults.clinicPhoneNum,
    clinicSmsNum: data.clinicSmsNum ?? defaults.clinicSmsNum,
    clinicWebChatNum: data.clinicWebChatNum ?? defaults.clinicWebChatNum,
    supportHtml: data.supportHtml ?? defaults.supportHtml,
    languageSettings: languageSettings,
    timeZone: data.timeZone ?? defaults.timeZone,
    webChatUrl: data.webChatUrl ?? defaults.webChatUrl,
    chatUrl: data.chatUrl ?? defaults.chatUrl,
    ehrConnectorBaseUrl:
      data.ehrConnectorBaseUrl ?? defaults.ehrConnectorBaseUrl,
    locationId: data.locationId ?? defaults.locationId,
    subDomain: data.subDomain ?? defaults.subDomain,
    gcpProjectId: data.gcpProjectId ?? defaults.gcpProjectId,
    appointmentCreatedCallbacks:
      data.appointmentCreatedCallbacks ?? defaults.appointmentCreatedCallbacks,
    maxHipaaRemindersCount:
      data.maxHipaaRemindersCount ?? defaults.maxHipaaRemindersCount,
    hipaaMessageResendIntervalMinutes:
      data.hipaaMessageResendIntervalMinutes ??
      defaults.hipaaMessageResendIntervalMinutes,
    patientCsvUploadedCallbacks:
      data.patientCsvUploadedCallbacks ?? defaults.patientCsvUploadedCallbacks,
    patientCreationWorkflowUid:
      data.patientCreationWorkflowUid ?? defaults.patientCreationWorkflowUid,
  };
};

export const clinicDetailsConverter: FirestoreDataConverter<ClinicDetails> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>
  ): ClinicDetails => {
    const data = snapshot.data();
    return clinicDetailsConverterFromfirestore(data);
  },
  toFirestore: (data: ClinicDetails): DocumentData => {
    let languageSettings: any[] = [];
    if (data.languageSettings) {
      data.languageSettings.forEach((setting: any) =>
        languageSettings.push(languageSettingConverter.toFirestore(setting))
      );
    }

    return {
      ...data,
      languageSettings: languageSettings,
    };
  },
};

export const providerConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultProvider };
  if (!data) {
    return defaults;
  }

  return {
    providerUid: data.providerUid ?? defaults.providerUid,
    firstName: data.firstName ?? defaults.firstName,
    lastName: data.lastName ?? defaults.lastName,
    email: data.email ?? defaults.email,
    phoneNum: data.phoneNum ?? defaults.phoneNum,
    accessLevel: data.accessLevel ?? defaults.accessLevel,
    createdAt: data.createdAt?.toDate()?.toISOString() ?? defaults.createdAt,
    createdBy: data.createdBy ?? defaults.createdBy,
    lastUpdatedAt:
      data.lastUpdatedAt?.toDate()?.toISOString() ?? defaults.lastUpdatedAt,
    lastUpdatedBy: data.lastUpdatedBy ?? defaults.lastUpdatedBy,
  };
};

export const providerConverter: FirestoreDataConverter<Provider> = {
  fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>): Provider => {
    const data = snapshot.data();
    return providerConverterFromfirestore(data);
  },
  toFirestore: (data: Provider): DocumentData => {
    return {
      ...data,
      createdAt: Timestamp.fromDate(new Date(data.createdAt)),
      lastUpdatedAt: Timestamp.fromDate(new Date(data.lastUpdatedAt)),
    };
  },
};

export const variableConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultVariable };
  if (!data) {
    return defaults;
  }

  let value: string | number | string[] | boolean = [];
  let defaultValue: string | number | string[] | boolean = [];
  let valueOptions: string[] = [];
  const type = data.type ?? defaults.type;
  switch (type) {
    case VariableTypes.DATE:
    case VariableTypes.DATETIME:
    case VariableTypes.TIME:
      value = data.value?.toDate()?.toISOString() ?? defaults.value;
      defaultValue =
        data.defaultValue?.toDate()?.toISOString() ?? defaults.defaultValue;
      break;
    case VariableTypes.PHONE_NUMBER:
    case VariableTypes.EMAIL:
    case VariableTypes.STRING:
    case VariableTypes.SINGLE_SELECT_TEXT:
    case VariableTypes.MULTI_SELECT_TEXT:
    case VariableTypes.BOOLEAN:
    case VariableTypes.DURATION:
    case VariableTypes.NUMBER:
      value = data.value ?? defaults.value;
      defaultValue = data.defaultValue ?? defaults.defaultValue;
      valueOptions = data.valueOptions ?? defaults.valueOptions;
      break;
    default:
      throw new BackendError(
        `Incorrect variable type: '${type} for value: ${
          data.value ?? defaults.value
        }'`,
        BackendErrorType.UNKNOWN_VARIABLE
      );
  }

  return {
    name: data.name ?? defaults.name,
    title: data.title ?? defaults.title,
    type: type,
    defaultValue: defaultValue,
    value: value,
    valueOptions: valueOptions,
    transformations: data.transformations ?? defaults.transformations,
    isRequired: data.isRequired ?? defaults.isRequired,
    isVisible: data.isVisible ?? defaults.isVisible,
    isDepricated: data.isDepricated ?? defaults.isDepricated,
    createdAt: data.createdAt?.toDate().toISOString() ?? defaults.createdAt,
    lastUpdatedAt:
      data.lastUpdatedAt?.toDate().toISOString() ?? defaults.lastUpdatedAt,
  };
};

export const variableConverter: FirestoreDataConverter<Variable> = {
  fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>): Variable => {
    const data = snapshot.data();
    return variableConverterFromfirestore(data);
  },
  toFirestore: (data: Variable): DocumentData => {
    let value: any = data.value;
    let defaultValue: any = data.defaultValue;
    let valueOptions: any = data.valueOptions;
    switch (data.type) {
      case VariableTypes.DATE:
      case VariableTypes.TIME:
      case VariableTypes.DATETIME:
        value = Timestamp.fromDate(new Date(value));
        defaultValue = Timestamp.fromDate(new Date(defaultValue));
        break;
    }
    return {
      ...data,
      value: value,
      defaultValue: defaultValue,
      valueOptions: valueOptions,
      createdAt: Timestamp.fromDate(new Date(data.createdAt)),
      lastUpdatedAt: Timestamp.fromDate(new Date(data.lastUpdatedAt)),
    };
  },
};

export const patientAboutConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultPatientAbout };
  if (!data) {
    return defaults;
  }

  return {
    firstName: data.firstName ?? defaults.firstName,
    lastName: data.lastName ?? defaults.lastName,
    phoneNum: data.phoneNum ?? defaults.phoneNum,
    email: data.email ?? defaults.email,
    gender: data.gender ?? defaults.gender,
    dob: data.dob?.toDate()?.toISOString() ?? defaults.dob,
    address: data.address ?? defaults.address,
    preferredLanguage: data.preferredLanguage ?? defaults.preferredLanguage,
  };
};

export const patientAboutConverter: FirestoreDataConverter<PatientAbout> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>
  ): PatientAbout => {
    const data = snapshot.data();
    return patientAboutConverterFromfirestore(data);
  },
  toFirestore: (data: PatientAbout): DocumentData => {
    return {
      ...data,
      dob: Timestamp.fromDate(new Date(data.dob)),
    };
  },
};

export const patientHipaaStatusConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultPatientHipaaStatus };
  if (!data) {
    return defaults;
  }

  return {
    hipaaComplianceForSmsRecieved:
      data.hipaaComplianceForSmsRecieved ??
      defaults.hipaaComplianceForSmsRecieved,
    hipaaComplianceForSmsRejected:
      data.hipaaComplianceForSmsRejected ??
      defaults.hipaaComplianceForSmsRejected,
    hipaaComplianceForSmsStopped:
      data.hipaaComplianceForSmsStopped ??
      defaults.hipaaComplianceForSmsStopped,
  };
};

export const patientHipaaStatusConverter: FirestoreDataConverter<PatientHipaaStatus> =
  {
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<DocumentData>
    ): PatientHipaaStatus => {
      const data = snapshot.data();
      return patientHipaaStatusConverterFromfirestore(data);
    },
    toFirestore: (data: PatientHipaaStatus): DocumentData => {
      return {
        ...data,
      };
    },
  };

export const workflowSummaryConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultWorkflowSummary };
  if (!data) {
    return defaults;
  }

  return {
    workflowUid: data.workflowUid ?? defaults.workflowUid,
    workflowName: data.workflowName ?? defaults.workflowName,
    totalTasks: data.totalTasks ?? defaults.totalTasks,
    tasksPending: data.tasksPending ?? defaults.tasksPending,
    tasksDone: data.tasksDone ?? defaults.tasksDone,
    tasksInErrorState: data.tasksInErrorState ?? defaults.tasksInErrorState,
    createdAt: data.createdAt?.toDate().toISOString() ?? defaults.createdAt,
  };
};

export const workflowSummaryConverter: FirestoreDataConverter<WorkflowSummary> =
  {
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<DocumentData>
    ): WorkflowSummary => {
      const data = snapshot.data();
      return workflowSummaryConverterFromfirestore(data);
    },
    toFirestore: (data: WorkflowSummary): DocumentData => {
      return {
        ...data,
        createdAt: Timestamp.fromDate(new Date(data.createdAt)),
      };
    },
  };

export const patientConverterFromfirestore = (data: any): Patient => {
  const defaults = { ...defaultPatient };
  if (!data) {
    return defaults;
  }

  let medicalDetails: Variable[] = [];
  if (data.medicalDetails) {
    data.medicalDetails.forEach((variable: any) =>
      medicalDetails.push(variableConverterFromfirestore(variable))
    );
  }

  let currentAssignedWorkflows: WorkflowSummary[] = [];
  if (data.currentAssignedWorkflows) {
    data.currentAssignedWorkflows.forEach((workflowSummary: any) =>
      currentAssignedWorkflows.push(
        workflowSummaryConverterFromfirestore(workflowSummary)
      )
    );
  }

  return {
    patientUid: data.patientUid ?? defaults.patientUid,
    ehrUid: data.ehrUid ?? defaults.ehrUid,
    MRN: data.MRN ?? defaults.MRN,
    about: patientAboutConverterFromfirestore(data.about),
    medicalDetails: medicalDetails,
    hipaaStatus: patientHipaaStatusConverterFromfirestore(data.hipaaStatus),
    currentAssignedWorkflows: currentAssignedWorkflows,
    currentAssignedWorkflowUid:
      data.currentAssignedWorkflowUid ?? defaults.currentAssignedWorkflowUid,
    currentAssignedWorkflowName:
      data.currentAssignedWorkflowName ?? defaults.currentAssignedWorkflowName,
    createdAt: data.createdAt?.toDate().toISOString() ?? defaults.createdAt,
    createdBy: data.createdBy ?? defaults.createdBy,
    lastUpdatedAt:
      data.lastUpdatedAt?.toDate().toISOString() ?? defaults.lastUpdatedAt,
    lastUpdatedBy: data.lastUpdatedBy ?? defaults.lastUpdatedBy,
    isArchived: data.isArchived ?? defaults.isArchived,
    conversationInfo: conversationInfoConverterFromfirestore(
      data.conversationInfo
    ),
  };
};

export const patientConverter: FirestoreDataConverter<Patient> = {
  fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>): Patient => {
    const data = snapshot.data();
    return patientConverterFromfirestore(data);
  },
  toFirestore: (data: Patient): DocumentData => {
    let medicalDetails: any[] = [];
    if (data.medicalDetails) {
      data.medicalDetails.forEach((variable: any) =>
        medicalDetails.push(variableConverter.toFirestore(variable))
      );
    }

    let currentAssignedWorkflows: any[] = [];
    if (data.currentAssignedWorkflows) {
      data.currentAssignedWorkflows.forEach((workflowSummary: any) =>
        currentAssignedWorkflows.push(
          workflowSummaryConverter.toFirestore(workflowSummary)
        )
      );
    }

    return {
      ...data,
      about: patientAboutConverter.toFirestore(data.about),
      medicalDetails: medicalDetails,
      hipaaStatus: patientHipaaStatusConverter.toFirestore(data.hipaaStatus),
      createdAt: Timestamp.fromDate(new Date(data.createdAt)),
      lastUpdatedAt: Timestamp.fromDate(new Date(data.lastUpdatedAt)),
      conversationInfo: conversationInfoConverter.toFirestore(
        data.conversationInfo
      ),
      currentAssignedWorkflows: currentAssignedWorkflows,
    };
  },
};

export const medicationConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultMedication };
  if (!data) {
    return defaults;
  }

  return {
    name: data.name ?? defaults.name,
    frequency: data.frequency ?? defaults.frequency,
    numOfTotalDoses: data.numOfTotalDoses ?? defaults.numOfTotalDoses,
    isPrescriptionDrug: data.isPrescriptionDrug ?? defaults.isPrescriptionDrug,
    numOfRemindersSent: data.numOfRemindersSent ?? defaults.numOfRemindersSent,
  };
};

export const medicationConverter: FirestoreDataConverter<Medication> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>
  ): Medication => {
    const data = snapshot.data();
    return medicationConverterFromfirestore(data);
  },
  toFirestore: (data: Medication): DocumentData => {
    return {
      ...data,
    };
  },
};

export const medicationCollectionObjectConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultMedicationCollectionObject };
  if (!data) {
    return defaults;
  }

  return {
    ...medicationConverterFromfirestore(data),
    medicationUid: data.medicationUid ?? defaults.medicationUid,
    createdAt: data.createdAt?.toDate().toISOString() ?? defaults.createdAt,
    createdBy: data.createdBy ?? defaults.createdBy,
    lastUpdatedAt:
      data.lastUpdatedAt?.toDate().toISOString() ?? defaults.lastUpdatedAt,
    lastUpdatedBy: data.lastUpdatedBy ?? defaults.lastUpdatedBy,
  };
};

export const medicationCollectionObjectConverter: FirestoreDataConverter<MedicationCollectionObject> =
  {
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<DocumentData>
    ): MedicationCollectionObject => {
      const data = snapshot.data();
      return medicationCollectionObjectConverterFromfirestore(data);
    },
    toFirestore: (data: MedicationCollectionObject): DocumentData => {
      return {
        ...data,
        ...medicationConverter.toFirestore(data),
        createdAt: Timestamp.fromDate(new Date(data.createdAt)),
        lastUpdatedAt: Timestamp.fromDate(new Date(data.lastUpdatedAt)),
      };
    },
  };

export const workflowTaskConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultWorkflowTask };
  if (!data) {
    return defaults;
  }

  return {
    workflowTaskUid: data.workflowTaskUid ?? defaults.workflowTaskUid,
    name: data.name ?? defaults.name,
    timeline: data.timeline ?? defaults.timeline,
    executionTimeCondition:
      data.executionTimeCondition ?? defaults.executionTimeCondition,
    executionTimestamp:
      data.executionTimestamp?.toDate().toISOString() ??
      defaults.executionTimestamp,
    lastExecutionTimestamp:
      data.lastExecutionTimestamp?.toDate().toISOString() ??
      defaults.lastExecutionTimestamp,
    patientMessageContentWithPlaceholders:
      data.patientMessageContentWithPlaceholders ??
      defaults.patientMessageContentWithPlaceholders,
    patientMessageContent:
      data.patientMessageContent ?? defaults.patientMessageContent,
    taskEligibilityCondition:
      data.taskEligibilityCondition ?? defaults.taskEligibilityCondition,
    isEligibleMessage: data.isEligibleMessage ?? defaults.isEligibleMessage,
    isSendMessage: data.isSendMessage ?? defaults.isSendMessage,
    isTaskDone: data.isTaskDone ?? defaults.isTaskDone,
    hasError: data.hasError ?? defaults.hasError,
    errorMessages: data.errorMessages ?? defaults.errorMessages,
    totalRepeatations: data.totalRepeatations ?? defaults.totalRepeatations,
    repeatationsDone: data.repeatationsDone ?? defaults.repeatationsDone,
    repeatationIntervalSec:
      data.repeatationIntervalSec ?? defaults.repeatationIntervalSec,
    callbacks: data.callbacks ?? defaults.callbacks,
    createdAt: data.createdAt?.toDate().toISOString() ?? defaults.createdAt,
    createdBy: data.createdBy ?? defaults.createdBy,
    lastUpdatedAt:
      data.lastUpdatedAt?.toDate().toISOString() ?? defaults.lastUpdatedAt,
    lastUpdatedBy: data.lastUpdatedBy ?? defaults.lastUpdatedBy,
    messageSentBy: data.messageSentBy ?? defaults.messageSentBy,
  };
};

export const workflowTaskConverter: FirestoreDataConverter<WorkflowTask> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>
  ): WorkflowTask => {
    const data = snapshot.data();
    return workflowTaskConverterFromfirestore(data);
  },
  toFirestore: (data: WorkflowTask): DocumentData => {
    return {
      ...data,
      executionTimestamp: Timestamp.fromDate(new Date(data.executionTimestamp)),
      lastExecutionTimestamp: Timestamp.fromDate(
        new Date(data.lastExecutionTimestamp)
      ),
      createdAt: Timestamp.fromDate(new Date(data.createdAt)),
      lastUpdatedAt: Timestamp.fromDate(new Date(data.lastUpdatedAt)),
    };
  },
};

export const surgeryDetailsConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultSurgeryDetails };
  if (!data) {
    return defaults;
  }

  let medicationBeforeSurgery: Medication[] = [];
  if (data.medicationBeforeSurgery) {
    data.medicationBeforeSurgery.forEach((medication: any) =>
      medicationBeforeSurgery.push(medicationConverterFromfirestore(medication))
    );
  }

  let medicationAfterSurgery: Medication[] = [];
  if (data.medicationAfterSurgery) {
    data.medicationAfterSurgery.forEach((medication: any) =>
      medicationAfterSurgery.push(medicationConverterFromfirestore(medication))
    );
  }

  let variables: Variable[] = [];
  if (data.variables) {
    data.variables.forEach((variable: any) =>
      variables.push(variableConverterFromfirestore(variable))
    );
  }

  return {
    variables: variables,
    medicationBeforeSurgery: medicationBeforeSurgery,
    medicationAfterSurgery: medicationAfterSurgery,
  };
};

export const surgeryDetailsConverter: FirestoreDataConverter<SurgeryDetails> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>
  ): SurgeryDetails => {
    const data = snapshot.data();
    return surgeryDetailsConverterFromfirestore(data);
  },
  toFirestore: (data: SurgeryDetails): DocumentData => {
    let medicationBeforeSurgery: any[] = [];
    if (data.medicationBeforeSurgery) {
      data.medicationBeforeSurgery.forEach((medication: any) =>
        medicationBeforeSurgery.push(
          medicationConverter.toFirestore(medication)
        )
      );
    }

    let variables: any[] = [];
    if (data.variables) {
      data.variables.forEach((variable: any) =>
        variables.push(variableConverter.toFirestore(variable))
      );
    }

    let medicationAfterSurgery: any[] = [];
    if (data.medicationAfterSurgery) {
      data.medicationAfterSurgery.forEach((medication: any) =>
        medicationAfterSurgery.push(medicationConverter.toFirestore(medication))
      );
    }

    return {
      ...data,
      variables: variables,
      medicationBeforeSurgery: medicationBeforeSurgery,
      medicationAfterSurgery: medicationAfterSurgery,
    };
  },
};

export const workflowConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultWorkflow };
  if (!data) {
    return defaults;
  }

  let workflow: WorkflowTask[] = [];
  if (data.workflowTasks) {
    data.workflowTasks.forEach((workflowTask: any) =>
      workflow.push(workflowTaskConverterFromfirestore(workflowTask))
    );
  }

  return {
    workflowUid: data.workflowUid ?? defaults.workflowUid,
    templateWorkflowUid:
      data.templateWorkflowUid ?? defaults.templateWorkflowUid,
    workflowName: data.workflowName ?? defaults.workflowName,
    assignedPatientUid: data.assignedPatientUid ?? defaults.assignedPatientUid,
    assignedPatientPhoneNum:
      data.assignedPatientPhoneNum ?? defaults.assignedPatientPhoneNum,
    isActiveWorkflow: data.isActiveWorkflow ?? defaults.isActiveWorkflow,
    skipHipaaCompliance:
      data.skipHipaaCompliance ?? defaults.skipHipaaCompliance,
    skipWorkflowApptStatusDiffCheck:
      data.skipWorkflowApptStatusDiffCheck ??
      defaults.skipWorkflowApptStatusDiffCheck,
    surgeryDetails: surgeryDetailsConverterFromfirestore(data.surgeryDetails),
    workflowTasks: workflow,
    createdAt: data.createdAt?.toDate().toISOString() ?? defaults.createdAt,
    createdBy: data.createdBy ?? defaults.createdBy,
    lastUpdatedAt:
      data.lastUpdatedAt?.toDate().toISOString() ?? defaults.lastUpdatedAt,
    lastUpdatedBy: data.lastUpdatedBy ?? defaults.lastUpdatedBy,
  };
};

export const workflowConverter: FirestoreDataConverter<Workflow> = {
  fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>): Workflow => {
    const data = snapshot.data();
    return workflowConverterFromfirestore(data);
  },
  toFirestore: (data: Workflow): DocumentData => {
    let workflow: any[] = [];
    if (data.workflowTasks) {
      data.workflowTasks.forEach((workflowTask: any) =>
        workflow.push(workflowTaskConverter.toFirestore(workflowTask))
      );
    }

    return {
      ...data,
      workflowTasks: workflow,
      surgeryDetails: surgeryDetailsConverter.toFirestore(data.surgeryDetails),
      createdAt: Timestamp.fromDate(new Date(data.createdAt)),
      lastUpdatedAt: Timestamp.fromDate(new Date(data.lastUpdatedAt)),
    };
  },
};

export const twilioConfigsConverter: FirestoreDataConverter<TwilioConfigs> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>
  ): TwilioConfigs => {
    let configs = { ...defaultTwilioConfigs };
    const configsData = snapshot.data();
    const defaults = defaultTwilioConfigs;
    if (configsData) {
      configs = {
        accountSid: configsData.accountSid ?? defaults.accountSid,
        authToken: configsData.authToken ?? defaults.authToken,
        statusCallback: configsData.statusCallback ?? defaults.statusCallback,
        chatMessagingServiceSid:
          configsData.chatMessagingServiceSid ??
          defaults.chatMessagingServiceSid,
        webchatMessagingServiceSid:
          configsData.webchatMessagingServiceSid ??
          defaults.webchatMessagingServiceSid,
      };
    }
    return configs;
  },
  toFirestore: (data: TwilioConfigs): DocumentData => {
    return {
      ...data,
    };
  },
};

export const mailModoConfigsConverter: FirestoreDataConverter<MailModoConfigs> =
  {
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<DocumentData>
    ): MailModoConfigs => {
      let configs = { ...defaultMailModoConfigs };
      const configsData = snapshot.data();
      const defaults = defaultMailModoConfigs;
      if (configsData) {
        configs = {
          apiKey: configsData.apiKey ?? defaults.apiKey,
          webChatCampaignId:
            configsData.webChatCampaignId ?? defaults.webChatCampaignId,
          webChatReceiverEmails:
            configsData.webChatReceiverEmails ?? defaults.webChatReceiverEmails,
          patientReplyCampaignId:
            configsData.patientReplyCampaignId ??
            defaults.patientReplyCampaignId,
          patientReplyReceiverEmails:
            configsData.patientReplyReceiverEmails ??
            defaults.patientReplyReceiverEmails,
          patientAppointmentReplyCampaignId:
            configsData.patientAppointmentReplyCampaignId ??
            defaults.patientAppointmentReplyCampaignId,
          patientAppointmentReplyEmails:
            configsData.patientAppointmentReplyEmails ??
            defaults.patientAppointmentReplyEmails,
          patientReviewReasonCampaignId:
            configsData.patientReviewReasonCampaignId ??
            defaults.patientReviewReasonCampaignId,
          patientReviewReasonEmails:
            configsData.patientReviewReasonEmails ??
            defaults.patientReviewReasonEmails,
          patientAppointmentRescheduleCampaignId:
            configsData.patientAppointmentRescheduleCampaignId ??
            defaults.patientAppointmentRescheduleCampaignId,
          patientAppointmentRescheduleEmails:
            configsData.patientAppointmentRescheduleEmails ??
            defaults.patientAppointmentRescheduleEmails,
          voiceCallNotificationCampaignId:
            configsData.voiceCallNotificationCampaignId ??
            defaults.voiceCallNotificationCampaignId,
          voiceCallNotificationEmails:
            configsData.voiceCallNotificationEmails ??
            defaults.voiceCallNotificationEmails,
        };
      }
      return configs;
    },
    toFirestore: (data: MailModoConfigs): DocumentData => {
      return {
        ...data,
      };
    },
  };

export const mmsMediaConverterFromfirestore = (data: any): MmsMedia => {
  const defaults = { ...defaultMmsMedia };
  if (!data) {
    return defaults;
  }

  return {
    extension: data.extension ?? defaults.extension,
    mediaUrl: data.mediaUrl ?? defaults.mediaUrl,
    mediaSid: data.mediaSid ?? defaults.mediaSid,
  };
};

export const mmsMediaConverter: FirestoreDataConverter<MmsMedia> = {
  fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>): MmsMedia => {
    const data = snapshot.data();
    return mmsMediaConverterFromfirestore(data);
  },
  toFirestore: (data: MmsMedia): DocumentData => {
    return {
      ...data,
    };
  },
};

export const messageConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultMessage };
  if (!data) {
    return defaults;
  }

  const mmsMedia: MmsMedia[] = [];
  if (data.mmsMedia) {
    data.mmsMedia.forEach((media: MmsMedia) =>
      mmsMedia.push(mmsMediaConverterFromfirestore(media))
    );
  }

  return {
    messageUid: data.messageUid ?? defaults.messageUid,
    senderType: data.senderType ?? defaults.senderType,
    messageType: data.messageType ?? defaults.messageType,
    senderUid: data.senderUid ?? defaults.senderUid,
    smsMessageSid: data.smsMessageSid ?? defaults.smsMessageSid,
    patientUid: data.patientUid ?? defaults.patientUid,
    senderPhoneNum: data.senderPhoneNum ?? defaults.senderPhoneNum,
    recieverPhoneNum: data.recieverPhoneNum ?? defaults.recieverPhoneNum,
    messageBody: data.messageBody ?? defaults.messageBody,
    mmsMedia: mmsMedia,
    status: data.status ?? defaults.status,
    errorCode: data.errorCode ?? defaults.errorCode,
    errorMessage: data.errorMessage ?? defaults.errorMessage,
    createdAt: data.createdAt?.toDate()?.toISOString() ?? defaults.createdAt,
    createdBy: data.createdBy ?? defaults.createdBy,
    lastUpdatedAt:
      data.lastUpdatedAt?.toDate().toISOString() ?? defaults.lastUpdatedAt,
    lastUpdatedBy: data.lastUpdatedBy ?? defaults.lastUpdatedBy,
  };
};

export const messageConverter: FirestoreDataConverter<Message> = {
  fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>): Message => {
    const data = snapshot.data();
    return messageConverterFromfirestore(data);
  },
  toFirestore: (data: Message): DocumentData => {
    let mmsMedia: any[] = [];
    if (data.mmsMedia) {
      data.mmsMedia.forEach((media: any) =>
        mmsMedia.push(mmsMediaConverter.toFirestore(media))
      );
    }

    // createdAt and lastUpdatedAt may be populated using firestore.serverTimestamp()
    // In such cases, the FieldValue type of object needs to be passed directly to Firestore
    return {
      ...data,
      mmsMedia: mmsMedia,
      createdAt:
        typeof data.createdAt === 'string'
          ? Timestamp.fromDate(new Date(data.createdAt))
          : data.createdAt,
      lastUpdatedAt:
        typeof data.lastUpdatedAt === 'string'
          ? Timestamp.fromDate(new Date(data.lastUpdatedAt))
          : data.lastUpdatedAt,
    };
  },
};

export const conversationInfoConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultConversationInfo };
  if (!data) {
    return defaults;
  }

  const convertedMessage = messageConverterFromfirestore(data.lastMessage);

  return {
    lastMessage: convertedMessage,
    firstMessageUid: data.firstMessageUid ?? defaults.firstMessageUid,
    lastHealthcareProviderMsgTime:
      data.lastHealthcareProviderMsgTime?.toDate().toISOString() ??
      defaults.lastHealthcareProviderMsgTime,
    chatbotState: data.chatbotState ?? defaults.chatbotState,
    hipaaRemindersSent: data.hipaaRemindersSent ?? defaults.hipaaRemindersSent,
    isSmsServiceDisabled:
      data.isSmsServiceDisabled ?? defaults.isSmsServiceDisabled,
    isRead: data.isRead ?? defaults.isRead,
  };
};

export const conversationInfoConverter: FirestoreDataConverter<ConversationInfo> =
  {
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<DocumentData>
    ): ConversationInfo => {
      const data = snapshot.data();
      return conversationInfoConverterFromfirestore(data);
    },
    toFirestore: (data: ConversationInfo): DocumentData => {
      const convertedMessage: any = messageConverter.toFirestore(
        data.lastMessage
      );
      return {
        ...data,
        lastHealthcareProviderMsgTime: Timestamp.fromDate(
          new Date(data.lastHealthcareProviderMsgTime)
        ),
        lastMessage: convertedMessage,
      };
    },
  };

export const formVariablesConverterFromfirestore = (
  data: any
): FormVariables => {
  const defaults = { ...defaultFormVariables };
  if (!data) {
    return defaults;
  }

  let patientMedicalDetails: Variable[] = [];
  if (data.patientMedicalDetails) {
    data.patientMedicalDetails.forEach((variable: any) =>
      patientMedicalDetails.push(variableConverterFromfirestore(variable))
    );
  }

  let appointmentVariables: Variable[] = [];
  if (data.appointmentVariables) {
    data.appointmentVariables.forEach((variable: any) =>
      appointmentVariables.push(variableConverterFromfirestore(variable))
    );
  }

  let importPatientsFromCsvVariables: Variable[] = [];
  if (data.importPatientsFromCsvVariables) {
    data.importPatientsFromCsvVariables.forEach((variable: any) =>
      importPatientsFromCsvVariables.push(
        variableConverterFromfirestore(variable)
      )
    );
  }

  return {
    patientMedicalDetails: patientMedicalDetails,
    appointmentVariables: appointmentVariables,
    importPatientsFromCsvVariables: importPatientsFromCsvVariables,
  };
};

export const formVariablesConverter: FirestoreDataConverter<FormVariables> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>
  ): FormVariables => {
    const data = snapshot.data();
    return formVariablesConverterFromfirestore(data);
  },
  toFirestore: (data: FormVariables): DocumentData => {
    let medicalDetails: any[] = [];
    if (data.patientMedicalDetails) {
      data.patientMedicalDetails.forEach((variable: any) =>
        medicalDetails.push(variableConverter.toFirestore(variable))
      );
    }

    let appointmentVariables: any[] = [];
    if (data.appointmentVariables) {
      data.appointmentVariables.forEach((variable: any) =>
        appointmentVariables.push(variableConverter.toFirestore(variable))
      );
    }

    let importPatientsFromCsvVariables: any[] = [];
    if (data.importPatientsFromCsvVariables) {
      data.importPatientsFromCsvVariables.forEach((variable: any) =>
        importPatientsFromCsvVariables.push(
          variableConverter.toFirestore(variable)
        )
      );
    }

    return {
      ...data,
      patientMedicalDetails: medicalDetails,
      appointmentVariables: appointmentVariables,
      importPatientsFromCsvVariables: importPatientsFromCsvVariables,
    };
  },
};

export const appointmentConverterFromfirestore = (data: any): Appointment => {
  const defaults = { ...defaultAppointment };
  if (!data) {
    return defaults;
  }

  let appointmentVariables: Variable[] = [];
  if (data.appointmentVariables) {
    data.appointmentVariables.forEach((variable: any) =>
      appointmentVariables.push(variableConverterFromfirestore(variable))
    );
  }

  return {
    appointmentUid: data.appointmentUid ?? defaults.appointmentUid,
    appointmentConnectorUid:
      data.appointmentConnectorUid ?? defaults.appointmentConnectorUid,
    patientUid: data.patientUid ?? defaults.patientUid,
    patientEhrUid: data.patientEhrUid ?? defaults.patientEhrUid,
    ehrUid: data.ehrUid ?? defaults.ehrUid,
    bookedInEhr: data.bookedInEhr ?? defaults.bookedInEhr,
    appointmentVariables: appointmentVariables,
    saraAppointmentStatus:
      data.saraAppointmentStatus ?? defaults.saraAppointmentStatus,
    startDateTime:
      data.startDateTime?.toDate().toISOString() ?? defaults.startDateTime,
    createdBy: data.createdBy ?? defaults.createdBy,
    createdAt: data.createdAt?.toDate()?.toISOString() ?? defaults.createdAt,
    lastUpdatedAt:
      data.lastUpdatedAt?.toDate()?.toISOString() ?? defaults.lastUpdatedAt,
    lastUpdatedBy: data.lastUpdatedBy ?? defaults.lastUpdatedBy,
  };
};

export const appointmentConverter: FirestoreDataConverter<Appointment> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>
  ): Appointment => {
    const data = snapshot.data();
    const defaults = { ...defaultAppointment };
    if (!data) {
      return defaults;
    }

    return appointmentConverterFromfirestore(data);
  },
  toFirestore: (data: Appointment): DocumentData => {
    let appointmentVariables: any[] = [];
    if (data.appointmentVariables) {
      data.appointmentVariables.forEach((variable: any) =>
        appointmentVariables.push(variableConverter.toFirestore(variable))
      );
    }

    return {
      ...data,
      appointmentVariables: appointmentVariables,
      startDateTime: Timestamp.fromDate(new Date(data.startDateTime)),
      createdAt: Timestamp.fromDate(new Date(data.createdAt)),
      lastUpdatedAt: Timestamp.fromDate(new Date(data.lastUpdatedAt)),
    };
  },
};

export const acuityCalendarRoutesConverterFromJson = (
  data: any
): AcuityCalendarRoutes[] => {
  const routes: AcuityCalendarRoutes[] = [];

  if (!data) {
    return routes;
  }

  data.forEach((jsonBlock: any) => {
    if (jsonBlock) {
      routes.push({
        calendarId:
          jsonBlock.calendarId ?? defaultAcuityCalendarRoutes.calendarId,
        gcpProjectId:
          jsonBlock.gcpProjectId ?? defaultAcuityCalendarRoutes.gcpProjectId,
      });
    }
  });
  return routes;
};

export const acuityConfigsConverter: FirestoreDataConverter<AcuityConfigs> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>
  ): AcuityConfigs => {
    let configs = { ...defaultAcuityConfigs };
    const configsData = snapshot.data();
    const defaults = defaultAcuityConfigs;
    if (configsData) {
      configs = {
        apiKey: configsData.apiKey ?? defaults.apiKey,
        calendarRoutes: acuityCalendarRoutesConverterFromJson(
          configsData.calendarRoutes
        ),
      };
    }
    return configs;
  },
  toFirestore: (data: AcuityConfigs): DocumentData => {
    return {
      ...data,
    };
  },
};

export const appointmentRequestConverterFromfirestore = (
  data: any
): AppointmentRequest => {
  const defaults = { ...defaultAppointmentRequest };
  if (!data) {
    return defaults;
  }

  return {
    appointmentRequestUid:
      data.appointmentRequestUid ?? defaults.appointmentRequestUid,
    bookingUid: data.bookingUid ?? defaults.bookingUid,
    calendarId: data.calendarId ?? defaults.calendarId,
    startDateTime:
      data.startDateTime?.toDate()?.toISOString() ?? defaults.startDateTime,
    endDateTime:
      data.endDateTime?.toDate()?.toISOString() ?? defaults.endDateTime,
    requestStatus: data.requestStatus ?? defaults.requestStatus,
    patientFirstName: data.patientFirstName ?? defaults.patientFirstName,
    patientLastName: data.patientLastName ?? defaults.patientLastName,
    patientPhoneNum: data.patientPhoneNum ?? defaults.patientPhoneNum,
    patientEmail: data.patientEmail ?? defaults.patientEmail,
    createdBy: data.createdBy ?? defaults.createdBy,
    createdAt: data.createdAt?.toDate()?.toISOString() ?? defaults.createdAt,
    lastUpdatedAt:
      data.lastUpdatedAt?.toDate()?.toISOString() ?? defaults.lastUpdatedAt,
    lastUpdatedBy: data.lastUpdatedBy ?? defaults.lastUpdatedBy,
  };
};

export const appointmentRequestConverter: FirestoreDataConverter<AppointmentRequest> =
  {
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<DocumentData>
    ): AppointmentRequest => {
      const data = snapshot.data();
      const defaults = { ...defaultAppointmentRequest };
      if (!data) {
        return defaults;
      }

      return appointmentRequestConverterFromfirestore(data);
    },
    toFirestore: (data: AppointmentRequest): DocumentData => {
      return {
        ...data,
        startDateTime: Timestamp.fromDate(new Date(data.startDateTime)),
        endDateTime: Timestamp.fromDate(new Date(data.endDateTime)),
        createdAt: Timestamp.fromDate(new Date(data.createdAt)),
        lastUpdatedAt: Timestamp.fromDate(new Date(data.lastUpdatedAt)),
      };
    },
  };

export const voiceCallConverterFromfirestore = (data: any) => {
  const defaults = { ...defaultVoiceCall };
  if (!data) {
    return defaults;
  }

  return {
    voiceCallUid: data.voiceCallUid ?? defaults.voiceCallUid,
    voiceCallSid: data.voiceCallSid ?? defaults.voiceCallSid,
    direction: data.direction ?? defaults.direction,
    callerPhoneNum: data.callerPhoneNum ?? defaults.callerPhoneNum,
    calledPhoneNum: data.calledPhoneNum ?? defaults.calledPhoneNum,
    callStatus: data.callStatus ?? defaults.callStatus,
    createdAt: data.createdAt?.toDate()?.toISOString() ?? defaults.createdAt,
    createdBy: data.createdBy ?? defaults.createdBy,
    lastUpdatedAt:
      data.lastUpdatedAt?.toDate().toISOString() ?? defaults.lastUpdatedAt,
    lastUpdatedBy: data.lastUpdatedBy ?? defaults.lastUpdatedBy,
  };
};

export const voiceCallConverter: FirestoreDataConverter<VoiceCall> = {
  fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>): VoiceCall => {
    const data = snapshot.data();
    return voiceCallConverterFromfirestore(data);
  },
  toFirestore: (data: VoiceCall): DocumentData => {
    // createdAt and lastUpdatedAt may be populated using firestore.serverTimestamp()
    // In such cases, the FieldValue type of object needs to be passed directly to Firestore
    return {
      ...data,
      createdAt:
        typeof data.createdAt === 'string'
          ? Timestamp.fromDate(new Date(data.createdAt))
          : data.createdAt,
      lastUpdatedAt:
        typeof data.lastUpdatedAt === 'string'
          ? Timestamp.fromDate(new Date(data.lastUpdatedAt))
          : data.lastUpdatedAt,
    };
  },
};
